import axios from "../utils/axios-util";

const assignmentsEndpoint = "/api/assignments";

const create = async (params) => {
  const { data } = await axios.post(assignmentsEndpoint, params);

  if (!data.data) {
    console.error("Error creating assignment");
    return [];
  }
  return data;
};

const repeatAssignment = async (params) => {
  const { data } = await axios.post(
    `${assignmentsEndpoint}/repeat_assignment`,
    params
  );

  if (!data.data) {
    console.error("Error creating assignment");
    return [];
  }
  return data;
};

const fetch = async (assignmentId) => {
  const { data } = await axios.get(
    `${assignmentsEndpoint}/edit/${assignmentId}`
  );

  if (!data.data) {
    console.error("Error creating assignment");
    return [];
  }
  return data;
};

const update = async (id, params) =>
  axios.put(`${assignmentsEndpoint}/${id}`, params);

const fetchSubjectAssignments = async (params) => {
  const { data } = await axios.get(
    `${assignmentsEndpoint}/subject_assignments`,
    params
  );

  if (!data) {
    console.error("Error fetching assignments from the API");
    return [];
  }
  return data;
};

const fetchAllAssignments = async (params) => {
  const { data } = await axios.get(assignmentsEndpoint, params);

  if (!data.data) {
    console.error("Error fetching assignments from the API");
    return [];
  }
  return data;
};

const fetchAllAssignmentsStudents = async (assignmentId, params) => {
  const { data } = await axios.get(
    `${assignmentsEndpoint}/${assignmentId}`,
    params
  );

  if (!data.data) {
    console.error("Error fetching students from the API");
    return [];
  }
  return data;
};

const updateStudentAssignment = async (assignmentStudent) => {
  await axios.put(
    `${assignmentsEndpoint}/${assignmentStudent.assignment_id}/student/${assignmentStudent.student_id}`,
    assignmentStudent
  );
};

const updateMultipleStudentAssignment = async (assignmentStudent) => {
  await axios.put(
    `${assignmentsEndpoint}/${assignmentStudent.assignment_id}/student/update_multiple_student_assignment_record`,
    assignmentStudent
  );
};

const deleteAssignment = (assignmentId) =>
  axios.delete(`${assignmentsEndpoint}/${assignmentId}`);

const deleteTurnedInOnline = (assignmentId, assignmentStudentId) =>
  axios.delete(`${assignmentsEndpoint}/${assignmentId}/turned_in_online`, {
    params: { assignmentStudentId },
  });

const attachments = (assignmentId) =>
  axios.get(`${assignmentsEndpoint}/${assignmentId}/attachments`);

const updateDisplayInPortal = (params) =>
  axios.put(
    `${assignmentsEndpoint}/${params.assignment_id}/update_portal_display`,
    params
  );

const updateMaxScore = async (params) => {
  const { data } = await axios.patch(
    `${assignmentsEndpoint}/${params.assignmentId}/update_max_score`,
    params
  );

  if (!data) {
    console.error("Error updating assignment");
    return [];
  }
  return data;
};

export default {
  create,
  fetch,
  fetchSubjectAssignments,
  fetchAllAssignmentsStudents,
  updateStudentAssignment,
  update,
  fetchAllAssignments,
  deleteAssignment,
  repeatAssignment,
  attachments,
  deleteTurnedInOnline,
  updateMultipleStudentAssignment,
  updateDisplayInPortal,
  updateMaxScore,
};
