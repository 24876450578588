import React, { useState, useEffect, useContext } from "react";
import {
  useLocation,
  useNavigate,
  generatePath,
  useSearchParams,
} from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Stack, Typography, Collapse } from "@mui/material";
import { font28, font18 } from "../sharedStyles";
import subjectService from "../../service/subjectService";
import { selectDropdown, selectDropdownOpen } from "../SelectDropdown.styles";
import subjectSelectContainer from "./SubjectSelect.styles";
import { QuickBarContext } from "../../context/QuickBarContext";

export default function SubjectSelect({ subjectId, schoolId, subjectName }) {
  const [selectOpen, setSelectOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const termId = searchParams.get("term") || null;
  const klassesIds = searchParams.get("classes");
  const quickBarContext = useContext(QuickBarContext);

  const [subjects, setSubjects] = useState([]);

  const handleChange = (value) => {
    const routeKeys = [
      "new-gradebook",
      "gradebook",
      "assignments",
      "students",
      "term-grade/class-grade",
      "term-grade/skills",
    ];

    let currentPath = "";

    routeKeys.forEach((route) => {
      if (location.pathname.includes("new-gradebook")) {
        currentPath = searchParams.get("term")
          ? `new-gradebook?term=${searchParams.get("term")}`
          : "new-gradebook";
      } else if (location.pathname.includes(route)) {
        if (klassesIds) {
          currentPath = searchParams.get("term")
            ? `${route}?term=${searchParams.get("term")}&classes=${klassesIds}`
            : route;
        }

        currentPath = searchParams.get("term")
          ? `${route}?&term=${searchParams.get("term")}`
          : route;
      }
    });

    const path = generatePath(
      `/school/:school_id/subjects/:subject_id/${currentPath}`,
      {
        school_id: schoolId,
        subject_id: value,
        term: termId,
      }
    );
    navigate(path);
    setSelectOpen(false);
  };

  const fetchAllSubjectsInTerm = async () => {
    const response = await subjectService.fetchAllSubjectsInTerm({
      params: {
        school_id: schoolId,
        term_id: termId,
        order: "asc",
        order_by: "name",
      },
    });
    if (response.data) {
      setSubjects(response.data);
    }
  };

  const fetchAllSubjectsInCurrentTerm = async () => {
    const response = await subjectService.fetchAllSubjectsInCurrentTerm({
      params: {
        school_id: schoolId,
        term_id: termId,
        order: "asc",
        order_by: "name",
      },
    });
    if (response.data) {
      setSubjects(response.data);
    }
  };

  useEffect(() => {
    if (termId) {
      fetchAllSubjectsInTerm();
    } else {
      fetchAllSubjectsInCurrentTerm();
    }
  }, [termId]);

  useEffect(() => {
    setSelectOpen(false);
  }, [location]);

  return (
    subjects.length > 0 && (
      <Box sx={subjectSelectContainer}>
        <Box
          sx={selectOpen ? selectDropdownOpen : selectDropdown}
          style={{ zIndex: quickBarContext.actionFired ? 0 : 9999 }}
        >
          <Stack
            direction="row"
            alignItems="center"
            onClick={() => setSelectOpen(!selectOpen)}
          >
            <Typography sx={font28} color="primary">
              {subjectName}
            </Typography>
            {selectOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Stack>
          <Collapse in={selectOpen} timeout={150}>
            {subjects
              .filter((subject) => subject.id !== Number(subjectId))
              .map((subject) => (
                <Typography
                  key={subject.id || subjectId}
                  sx={{ ...font18, py: "5px" }}
                  color="primary"
                  onClick={() => handleChange(subject.id)}
                >
                  {subject.name}
                </Typography>
              ))}
          </Collapse>
        </Box>
      </Box>
    )
  );
}
