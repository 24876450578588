import {
  Alert,
  AppBar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { EMAIL_PATTERN } from "interweave-autolink";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { renderError, requiredMessage } from "../../../utils/constants/forms";
import {
  alignStart,
  applyStyle,
  backgroundWhite,
  centerContent,
  dateOfBaptismInput,
  gridStyleUp,
  minWidthFit,
  submitButton,
} from "./RegistrationForm.style";
import { font16, font32, font40, formTitle } from "../../sharedStyles";
import { navBarTypography } from "../../TopNavBar/TopNavBar.style";
import theme from "../../../utils";
import lookupService from "../../../service/lookupService";
import countriesService from "../../../service/countriesService";
import schoolYearApplicationService from "../../../service/schoolYearApplicationService";
import GenericSubmitButton from "../../GenericSubmitButton";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import Authorize from "../../Authorize";

function RegistrationFormRender({
  loadRegistration,
  schoolYear,
  managePermission,
}) {
  const params = useParams();
  const { token } = params;
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);
  const navigate = useNavigate();
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: "",
    severity: null,
  });
  const [suffixList, setSuffixList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [ethnicityList, setEthnicityList] = useState([]);
  const [gradeLevelList, setGradeLevelList] = useState([]);
  const [guardiansList, setGuardiansList] = useState([]);
  const [states, setStates] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [searchParams] = useSearchParams();
  const [checkboxList, setCheckboxList] = useState([]);
  const [showDateOfBaptism, setShowDateOfBaptism] = useState(false);
  const [showSchoolInfo, setShowSchoolInfo] = useState(false);
  const [
    showHasBeenDisciplinedExplanation,
    setShowHasBeenDisciplinedExplanation,
  ] = useState(false);
  const [showHasBeenInTroubleExplanation, setShowHasBeenInTroubleExplanation] =
    useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen({ message: "", open: false });
  };

  const postalCodePattern = {
    value: /^(?!0{5})\d{5}$/,
    message: "Invalid format. Ex: 95928",
  };

  const phoneNumberPattern = {
    value: /^\(\d{3}\) \d{3}-\d{4}$/,
    message: "Invalid format. Ex: (123) 456-7890",
  };

  const requiredValidation = {
    value: true,
    message: requiredMessage,
  };

  const maxLengthValidation = (value) => ({
    value,
    message: `Cannot exceed ${value} characters.`,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    reset,
    getValues,
    watch,
  } = useForm();

  const onSubmit = async (formData) => {
    if (checkboxList.length > 0) {
      try {
        const response = await schoolYearApplicationService.create({
          ...formData,
          registration_token: token,
        });
        if (response.data) {
          setSnackBarOpen({ open: true, message: "Application submitted!" });
        }
        reset();
        setShowConfirmationScreen(true);
      } catch (e) {
        setSnackBarOpen({
          open: true,
          message: "An error occurred. Please try again.",
          severity: "error",
        });
      }
    }
  };

  useEffect(() => {
    const services = [
      lookupService.fetchSuffixes(),
      lookupService.fetchGenders(),
      lookupService.fetchSchoolEthnicities({
        params: {
          school_id: schoolYear?.school_id,
        },
      }),
      lookupService.fetchGradeLevels(),
      lookupService.fetchGuardians(),
      countriesService.fetchAllStates(),
      lookupService.fetchApplicationStatuses(),
    ];

    if (loadRegistration) {
      services.push(schoolYearApplicationService.fetchById(params.id));
    }
    Promise.all(services).then((response) => {
      setSuffixList(response[0].data.data);
      setGenderList(response[1].data.data);
      setEthnicityList(response[2].data.data);
      setGradeLevelList(response[3].data.data);
      setGuardiansList(response[4].data.data);
      setStates(response[5].data);
      setStatusList(response[6].data.data);

      if (loadRegistration) {
        const loadedApplication = response[7].data.school_year_application;
        loadedApplication.resides_with = loadedApplication.resides_with
          .split(",")
          .map((name) => name.trim());
        setShowDateOfBaptism(loadedApplication.is_seventh_day_adventist);
        setShowSchoolInfo(loadedApplication.is_transferring_from_school);
        setShowHasBeenDisciplinedExplanation(
          loadedApplication.has_been_disciplined
        );
        setShowHasBeenInTroubleExplanation(
          loadedApplication.has_been_in_trouble_with_the_law
        );
        reset(loadedApplication);
      }
    });
  }, [searchParams]);

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    const { id } = event.target;
    const index = checkboxList.indexOf(id);
    if (checked && index === -1) {
      setCheckboxList([...checkboxList, id]);
    }
    if (!checked && index !== -1) {
      setCheckboxList(checkboxList.filter((item) => item !== id));
    }
  };

  const handleStatusChange = async () => {
    const response = await schoolYearApplicationService.update({
      ...getValues(),
    });

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Status updated.",
      });
    }
  };

  const authorizedPickUpRequired = (number) => {
    const values = [
      watch(`authorized_pick_up_${number}_first_name`),
      watch(`authorized_pick_up_${number}_last_name`),
      watch(`authorized_pick_up_${number}_phone_number`),
      watch(`authorized_pick_up_${number}_relationship`),
    ];
    return values.some((value) => value !== "");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showConfirmationScreen ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={centerContent}
        >
          <Grid item xs={12}>
            <Typography sx={font32}>Thank you for registering</Typography>
            <Grid item xs={12} textAlign="center" mt={2}>
              <Button sx={submitButton} onClick={() => navigate(0)}>
                Register Another Student
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container>
            {!loadRegistration && (
              <AppBar>
                <Toolbar variant="dense">
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs ml={3}>
                      <Typography noWrap component="div" sx={navBarTypography}>
                        {schoolYear?.school?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            )}
            {loadRegistration && (
              <Grid
                item
                xs={2}
                mx={() =>
                  (useMediaQuery(theme.breakpoints.up("lg")) && 35) || "unset"
                }
                mt={4}
              >
                <FormControl fullWidth error={!!errors?.status}>
                  <InputLabel id="status" shrink sx={backgroundWhite}>
                    Application Status
                  </InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Select
                        labelId="status"
                        id="status"
                        value={value}
                        disabled={!managePermission}
                        onChange={(e) => {
                          onChange(e);
                          handleStatusChange();
                        }}
                        // {...register("status", {})}
                      >
                        <MenuItem disabled value="" />
                        {statusList.map((status) => (
                          <MenuItem key={status.id} value={status.name}>
                            {status.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid
              container
              justifyContent="center"
              spacing={2}
              pt={loadRegistration ? 1 : 7}
            >
              {!loadRegistration && (
                <>
                  <Grid item xs={12} md={5} sx={gridStyleUp}>
                    <Typography sx={font40}>
                      {schoolYear?.school?.name}
                    </Typography>
                    <Typography sx={applyStyle}>
                      Apply now for the <br /> {schoolYear?.name} school year
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7} textAlign="left">
                    <Typography
                      sx={font16}
                      mt={() =>
                        (useMediaQuery(theme.breakpoints.up("lg")) && 8) || 2
                      }
                    >
                      Registration is now open for the upcoming school year.
                      <br />
                      Thank you for your interest in having your child join our
                      school. <br /> Please complete the following form.
                    </Typography>
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={12}
                mx={() =>
                  (useMediaQuery(theme.breakpoints.up("lg")) && 35) || "unset"
                }
                mt={loadRegistration ? 1 : 4}
                display="flex"
              >
                <Typography sx={formTitle}>
                  Parent&apos;s Information
                </Typography>
              </Grid>

              <Grid
                container
                item
                xs={12}
                mx={() =>
                  (useMediaQuery(theme.breakpoints.up("lg")) && 35) || "unset"
                }
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="parent_first_name"
                    label="First Name *"
                    error={!!errors?.parent_first_name}
                    fullWidth
                    {...register("parent_first_name", {
                      required: requiredValidation,
                      maxLength: maxLengthValidation(50),
                      disabled: loadRegistration,
                    })}
                  />
                  {errors.parent_first_name &&
                    renderError(errors.parent_first_name.message)}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="parent_last_name"
                    label="Last Name *"
                    error={!!errors?.parent_last_name}
                    fullWidth
                    {...register("parent_last_name", {
                      required: requiredValidation,
                      maxLength: maxLengthValidation(200),
                      disabled: loadRegistration,
                    })}
                  />
                  {errors.parent_last_name &&
                    renderError(errors.parent_last_name.message)}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="parent_phone_number"
                    label="Phone Number *"
                    error={!!errors?.parent_phone_number}
                    fullWidth
                    {...register("parent_phone_number", {
                      required: requiredValidation,
                      pattern: phoneNumberPattern,
                      disabled: loadRegistration,
                    })}
                  />
                  {errors.parent_phone_number &&
                    renderError(errors.parent_phone_number.message)}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="parent_email"
                    label="Email *"
                    error={!!errors?.parent_email}
                    fullWidth
                    {...register("parent_email", {
                      required: requiredValidation,
                      maxLength: maxLengthValidation(100),
                      pattern: {
                        value: EMAIL_PATTERN,
                        message: "Invalid format.",
                      },
                      disabled: loadRegistration,
                    })}
                  />
                  {errors.parent_email &&
                    renderError(errors.parent_email.message)}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                mx={() =>
                  (useMediaQuery(theme.breakpoints.up("lg")) && 35) || "unset"
                }
                mt={4}
              >
                <Typography sx={formTitle}>
                  Student&apos;s Information
                </Typography>
              </Grid>

              <Grid
                container
                item
                xs={12}
                mx={() =>
                  (useMediaQuery(theme.breakpoints.up("lg")) && 35) || "unset"
                }
                spacing={2}
              >
                <Grid item xs={12} md={3}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="first_name"
                    label="First Name *"
                    error={!!errors?.first_name}
                    fullWidth
                    {...register("first_name", {
                      required: requiredValidation,
                      maxLength: maxLengthValidation(50),
                      disabled: loadRegistration,
                    })}
                  />
                  {errors.first_name && renderError(errors.first_name.message)}
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="middle_name"
                    label="Middle Name"
                    fullWidth
                    {...register("middle_name", {
                      maxLength: maxLengthValidation(50),
                      disabled: loadRegistration,
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="last_name"
                    label="Last Name *"
                    error={!!errors?.last_name}
                    fullWidth
                    {...register("last_name", {
                      required: requiredValidation,
                      maxLength: maxLengthValidation(200),
                      disabled: loadRegistration,
                    })}
                  />
                  {errors.last_name && renderError(errors.last_name.message)}
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth error={!!errors?.suffix}>
                    <InputLabel id="suffix" shrink sx={backgroundWhite}>
                      Suffix
                    </InputLabel>
                    <Controller
                      name="suffix"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="suffix"
                          id="suffix"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          {...register("suffix", {
                            disabled: loadRegistration,
                          })}
                        >
                          <MenuItem disabled value="" />
                          {suffixList.map((suffix) => (
                            <MenuItem key={suffix.id} value={suffix.name}>
                              {suffix.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="preferred_name"
                    label="Preferred Name"
                    error={!!errors?.preferred_name}
                    fullWidth
                    {...register("preferred_name", {
                      maxLength: maxLengthValidation(100),
                      disabled: loadRegistration,
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth error={!!errors?.gender}>
                    <InputLabel id="gender" shrink sx={backgroundWhite}>
                      Gender *
                    </InputLabel>
                    <Controller
                      name="gender"
                      control={control}
                      defaultValue=""
                      rules={{ required: requiredValidation }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="gender"
                          id="gender"
                          value={value}
                          onChange={(e) => onChange(e)}
                          {...register("gender", {
                            disabled: loadRegistration,
                          })}
                        >
                          <MenuItem disabled value="" />
                          {genderList.map((gender) => (
                            <MenuItem key={gender.id} value={gender.name}>
                              {gender.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.gender && renderError(errors.gender.message)}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth error={!!errors?.ethnicity}>
                    <InputLabel id="ethnicity" shrink sx={backgroundWhite}>
                      Ethnicity *
                    </InputLabel>
                    <Controller
                      name="ethnicity"
                      control={control}
                      defaultValue=""
                      rules={{ required: requiredValidation }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="ethnicity"
                          id="ethnicity"
                          value={value}
                          onChange={(e) => onChange(e)}
                          {...register("ethnicity", {
                            disabled: loadRegistration,
                          })}
                        >
                          <MenuItem disabled value="" />
                          {ethnicityList.map((ethnicity) => (
                            <MenuItem key={ethnicity.id} value={ethnicity.name}>
                              {ethnicity.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.ethnicity && renderError(errors.ethnicity.message)}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="place_of_birth"
                    label="Place of Birth"
                    error={!!errors?.place_of_birth}
                    fullWidth
                    {...register("place_of_birth", {
                      maxLength: maxLengthValidation(200),
                      disabled: loadRegistration,
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="date_of_birth"
                    control={control}
                    rules={{
                      validate: {
                        validDate: (value) =>
                          (moment(value).isValid() &&
                            moment(value).isBefore(moment())) ||
                          "Invalid date.",
                      },
                      pattern: {
                        value:
                          /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
                        message: "Invalid date.",
                      },
                      required: requiredValidation,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label="Date of Birth *"
                        id="date_of_birth"
                        value={value || null}
                        maxDate={new Date()}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        disabled={loadRegistration}
                        renderInput={(_params) => (
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            {..._params}
                            error={!!errors?.date_of_birth}
                            fullWidth
                            disabled={loadRegistration}
                          />
                        )}
                      />
                    )}
                  />
                  {errors.date_of_birth &&
                    renderError(errors.date_of_birth.message)}
                </Grid>
                <Grid item xs={12} md={6} display="flex" mt={4} mb={4}>
                  <Typography>Students current grade:</Typography>
                  <FormControl fullWidth error={!!errors?.current_grade}>
                    <InputLabel id="current_grade" shrink sx={backgroundWhite}>
                      Grade *
                    </InputLabel>
                    <Controller
                      name="current_grade"
                      control={control}
                      defaultValue=""
                      rules={{ required: requiredValidation }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="current_grade"
                          id="current_grade"
                          value={value}
                          onChange={(e) => onChange(e)}
                          {...register("current_grade", {
                            disabled: loadRegistration,
                          })}
                        >
                          <MenuItem disabled value="" />
                          {gradeLevelList.map((currentGrade) => (
                            <MenuItem
                              key={currentGrade.id}
                              value={currentGrade.name}
                            >
                              {currentGrade.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.current_grade &&
                      renderError(errors.current_grade.message)}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} display="flex" mt={4} mb={4}>
                  <Typography>Grade student is applying for:</Typography>
                  <FormControl fullWidth error={!!errors?.current_grade}>
                    <InputLabel id="applying_grade" shrink sx={backgroundWhite}>
                      Grade *
                    </InputLabel>
                    <Controller
                      name="applying_grade"
                      control={control}
                      defaultValue=""
                      rules={{ required: requiredValidation }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="applying_grade"
                          id="applying_grade"
                          value={value}
                          onChange={(e) => onChange(e)}
                          {...register("applying_grade", {
                            disabled: loadRegistration,
                          })}
                        >
                          <MenuItem disabled value="" />
                          {gradeLevelList.map((currentGrade) => (
                            <MenuItem
                              key={currentGrade.id}
                              value={currentGrade.name}
                            >
                              {currentGrade.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.applying_grade &&
                      renderError(errors.applying_grade.message)}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={!!errors?.resides_with}>
                    <InputLabel id="resides_with" shrink sx={backgroundWhite}>
                      Resides With *
                    </InputLabel>
                    <Controller
                      name="resides_with"
                      control={control}
                      defaultValue={[]}
                      rules={{ required: requiredValidation }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="resides_with"
                          id="resides_with"
                          value={value}
                          onChange={(e) => onChange(e)}
                          multiple
                          {...register("resides_with", {
                            disabled: loadRegistration,
                          })}
                        >
                          <MenuItem disabled value="" />
                          {guardiansList.map((guardian) => (
                            <MenuItem key={guardian.id} value={guardian.name}>
                              {guardian.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.resides_with &&
                      renderError(errors.resides_with.message)}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="street_address"
                    label="Street Address *"
                    error={!!errors?.street_address}
                    fullWidth
                    {...register("street_address", {
                      required: requiredValidation,
                      maxLength: maxLengthValidation(200),
                      disabled: loadRegistration,
                    })}
                  />
                  {errors.street_address &&
                    renderError(errors.street_address.message)}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="city"
                    label="City *"
                    error={!!errors?.city}
                    fullWidth
                    {...register("city", {
                      required: requiredValidation,
                      maxLength: maxLengthValidation(50),
                      disabled: loadRegistration,
                    })}
                  />
                  {errors.city && renderError(errors.city.message)}
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth error={!!errors?.state_id}>
                    <InputLabel id="state_id" shrink sx={backgroundWhite}>
                      State *
                    </InputLabel>
                    <Controller
                      name="state_id"
                      control={control}
                      defaultValue=""
                      rules={{ required: requiredValidation }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="state_id"
                          id="state_id"
                          value={value}
                          onChange={(e) => onChange(e)}
                          {...register("state_id", {
                            disabled: loadRegistration,
                          })}
                        >
                          <MenuItem disabled value="" />
                          {states?.map((state) => (
                            <MenuItem key={state.id} value={state.id}>
                              {state.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.state_id && renderError(errors.state_id.message)}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="postal_code"
                    label="Postal Code *"
                    error={!!errors?.postal_code}
                    fullWidth
                    {...register("postal_code", {
                      required: requiredValidation,
                      maxLength: maxLengthValidation(5),
                      pattern: postalCodePattern,
                      disabled: loadRegistration,
                    })}
                  />
                  {errors.postal_code &&
                    renderError(errors.postal_code.message)}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="phone_number"
                    label="Phone Number"
                    error={!!errors?.phone_number}
                    fullWidth
                    {...register("phone_number", {
                      pattern: phoneNumberPattern,
                      disabled: loadRegistration,
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="email"
                    label="Email"
                    error={!!errors?.email}
                    fullWidth
                    {...register("email", {
                      maxLength: maxLengthValidation(100),
                      pattern: {
                        value: EMAIL_PATTERN,
                        message: "Invalid format.",
                      },
                      disabled: loadRegistration,
                    })}
                  />
                </Grid>
                <Grid item xs={12} display="flex" alignItems="center" mt={3}>
                  <Typography sx={font16} mr={2}>
                    Is student baptized Seventh-day Adventist?*
                    <br />
                    {errors.is_seventh_day_adventist &&
                      renderError(errors.is_seventh_day_adventist.message)}
                  </Typography>
                  <FormControl sx={minWidthFit}>
                    <Controller
                      name="is_seventh_day_adventist"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: requiredValidation,
                      }}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          {...field}
                          onChange={(event, value) => {
                            field.onChange(value);
                            setShowDateOfBaptism(value === "true");
                          }}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>Yes</Typography>
                              </Box>
                            }
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>No</Typography>
                              </Box>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} display="flex" alignItems="center" mt={3}>
                  <Typography sx={font16} mr={2}>
                    Is parent Seventh-day Adventist?*
                    <br />
                    {errors.is_parent_seventh_day_adventist &&
                      renderError(
                        errors.is_parent_seventh_day_adventist.message
                      )}
                  </Typography>
                  <FormControl sx={minWidthFit}>
                    <Controller
                      name="is_parent_seventh_day_adventist"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: requiredValidation,
                      }}
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>Yes</Typography>
                              </Box>
                            }
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>No</Typography>
                              </Box>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                {showDateOfBaptism && (
                  <Grid item xs={12}>
                    <Controller
                      name="date_of_baptism"
                      control={control}
                      rules={{
                        validate: {
                          validDate: (value) =>
                            (moment(value).isValid() &&
                              moment(value).isBefore(moment())) ||
                            "Invalid date.",
                        },
                        required: {
                          value: showDateOfBaptism,
                          message: requiredMessage,
                        },
                        pattern: {
                          value:
                            /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
                          message: "Invalid date.",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label="Date of Baptism *"
                          id="date_of_baptism"
                          value={value || null}
                          maxDate={new Date()}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          disabled={loadRegistration}
                          renderInput={(_params) => (
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              {..._params}
                              error={!!errors?.date_of_baptism}
                              sx={dateOfBaptismInput}
                              disabled={loadRegistration}
                            />
                          )}
                        />
                      )}
                    />
                    {errors.date_of_baptism &&
                      renderError(errors.date_of_baptism.message)}
                  </Grid>
                )}
                <Grid item xs={12} display="flex" alignItems="center" mt={3}>
                  <Typography sx={font16} mr={2}>
                    Is this your student&apos;s first year at this school?*
                    <br />
                    {errors.is_first_year &&
                      renderError(errors.is_first_year.message)}
                  </Typography>
                  <FormControl sx={minWidthFit}>
                    <Controller
                      name="is_first_year"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: requiredValidation,
                      }}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          {...field}
                          onChange={(event, value) => field.onChange(value)}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>Yes</Typography>
                              </Box>
                            }
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>No</Typography>
                              </Box>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} display="flex" alignItems="center" mt={3}>
                  <Typography sx={font16} mr={2}>
                    Is your student transferring from another school?* <br />
                    {errors.is_transferring_from_school &&
                      renderError(errors.is_transferring_from_school.message)}
                  </Typography>
                  <FormControl sx={minWidthFit}>
                    <Controller
                      name="is_transferring_from_school"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: requiredValidation,
                      }}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          {...field}
                          onChange={(event, value) => {
                            field.onChange(value);
                            setShowSchoolInfo(value === "true");
                          }}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>Yes</Typography>
                              </Box>
                            }
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>No</Typography>
                              </Box>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                {showSchoolInfo && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="school_name"
                        label="School Name *"
                        error={!!errors?.school_name}
                        fullWidth
                        {...register("school_name", {
                          required: {
                            value: showSchoolInfo,
                            message: requiredMessage,
                          },
                          maxLength: maxLengthValidation(200),
                          disabled: loadRegistration,
                        })}
                      />
                      {errors.school_name &&
                        renderError(errors.school_name.message)}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="school_street_address"
                        label="Street Address"
                        error={!!errors?.school_street_address}
                        fullWidth
                        {...register("school_street_address", {
                          maxLength: maxLengthValidation(200),
                          disabled: loadRegistration,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="school_city"
                        label="City"
                        error={!!errors?.school_city}
                        fullWidth
                        {...register("school_city", {
                          maxLength: maxLengthValidation(50),
                          disabled: loadRegistration,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth error={!!errors?.school_state_id}>
                        <InputLabel
                          id="school_state_id"
                          shrink
                          sx={backgroundWhite}
                        >
                          State
                        </InputLabel>
                        <Controller
                          name="school_state_id"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <Select
                              labelId="school_state_id"
                              id="school_state_id"
                              value={value}
                              onChange={(e) => onChange(e)}
                              {...register("school_state_id", {
                                disabled: loadRegistration,
                              })}
                            >
                              <MenuItem disabled value="" />
                              {states?.map((state) => (
                                <MenuItem key={state.id} value={state.id}>
                                  {state.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="school_postal_code"
                        label="Postal Code"
                        error={!!errors?.school_postal_code}
                        fullWidth
                        {...register("school_postal_code", {
                          maxLength: maxLengthValidation(5),
                          pattern: postalCodePattern,
                          disabled: loadRegistration,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="school_phone_number"
                        label="Phone Number"
                        error={!!errors?.school_phone_number}
                        fullWidth
                        {...register("school_phone_number", {
                          pattern: phoneNumberPattern,
                          disabled: loadRegistration,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="school_email"
                        label="Email"
                        error={!!errors?.school_email}
                        fullWidth
                        {...register("school_email", {
                          maxLength: maxLengthValidation(100),
                          pattern: {
                            value: EMAIL_PATTERN,
                            message: "Invalid format.",
                          },
                          disabled: loadRegistration,
                        })}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} display="flex" alignItems="center" mt={3}>
                  <Typography sx={font16} mr={2}>
                    Has student ever had an IEP (Individualized Education
                    Program)?*
                    <br />
                    {errors.individualized_education_program &&
                      renderError(
                        errors.individualized_education_program.message
                      )}
                  </Typography>
                  <FormControl sx={minWidthFit}>
                    <Controller
                      name="individualized_education_program"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: requiredValidation,
                      }}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          {...field}
                          onChange={(event, value) => field.onChange(value)}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>Yes</Typography>
                              </Box>
                            }
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>No</Typography>
                              </Box>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} display="flex" alignItems="center" mt={3}>
                  <Typography sx={font16} mr={2}>
                    Has student ever been disciplined beyond ordinary classroom
                    situation?*
                    <br />
                    {errors.has_been_disciplined &&
                      renderError(errors.has_been_disciplined.message)}
                  </Typography>
                  <FormControl sx={minWidthFit}>
                    <Controller
                      name="has_been_disciplined"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: requiredValidation,
                      }}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          {...field}
                          onChange={(event, value) => {
                            field.onChange(value);
                            setShowHasBeenDisciplinedExplanation(
                              value === "true"
                            );
                          }}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>Yes</Typography>
                              </Box>
                            }
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>No</Typography>
                              </Box>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                {showHasBeenDisciplinedExplanation && (
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      id="disciplined_motive"
                      label="Please explain *"
                      error={!!errors?.disciplined_motive}
                      fullWidth
                      {...register("disciplined_motive", {
                        required: {
                          value: showHasBeenDisciplinedExplanation,
                          message: requiredMessage,
                        },
                        maxLength: maxLengthValidation(500),
                        disabled: loadRegistration,
                      })}
                    />
                    {errors.disciplined_motive &&
                      renderError(errors.disciplined_motive.message)}
                  </Grid>
                )}
                <Grid item xs={12} display="flex" alignItems="center" mt={3}>
                  <Typography sx={font16} mr={2}>
                    Has student ever been in trouble with the law or ever used
                    illegal substances?*
                    <br />
                    {errors.has_been_in_trouble_with_the_law &&
                      renderError(
                        errors.has_been_in_trouble_with_the_law.message
                      )}
                  </Typography>
                  <FormControl sx={minWidthFit}>
                    <Controller
                      name="has_been_in_trouble_with_the_law"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: requiredValidation,
                      }}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          {...field}
                          onChange={(event, value) => {
                            field.onChange(value);
                            setShowHasBeenInTroubleExplanation(
                              value === "true"
                            );
                          }}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>Yes</Typography>
                              </Box>
                            }
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>No</Typography>
                              </Box>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                {showHasBeenInTroubleExplanation && (
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      id="trouble_motive"
                      label="Please explain *"
                      error={!!errors?.trouble_motive}
                      fullWidth
                      {...register("trouble_motive", {
                        required: {
                          value: showHasBeenInTroubleExplanation,
                          message: requiredMessage,
                        },
                        maxLength: maxLengthValidation(500),
                        disabled: loadRegistration,
                      })}
                    />
                    {errors.trouble_motive &&
                      renderError(errors.trouble_motive.message)}
                  </Grid>
                )}
                <Grid item xs={12} display="flex" mt={3}>
                  <FormControl
                    component="fieldset"
                    variant="standard"
                    mt={3}
                    onChange={(e) => handleCheckboxChange(e)}
                  >
                    <Typography sx={font16} mr={2} mb={2}>
                      Student&apos;s attitude towards school and work habits*
                      (Please check all that apply)
                    </Typography>
                    <Box ml={3}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Controller
                              name="positive_attitude"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  disabled={loadRegistration}
                                  checked={!!props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Generally has a positive attitude"
                        />
                        <FormControlLabel
                          control={
                            <Controller
                              name="negative_attitude"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  disabled={loadRegistration}
                                  checked={!!props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Generally has a negative attitude"
                        />
                        <FormControlLabel
                          control={
                            <Controller
                              name="makes_friends_easily"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  disabled={loadRegistration}
                                  checked={!!props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Makes friends easily"
                        />
                        <FormControlLabel
                          control={
                            <Controller
                              name="has_trouble_socializing"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  disabled={loadRegistration}
                                  checked={!!props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Has trouble socializing"
                        />
                        <FormControlLabel
                          control={
                            <Controller
                              name="has_trouble_focused"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  disabled={loadRegistration}
                                  checked={!!props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Has trouble staying focused"
                        />
                        <FormControlLabel
                          control={
                            <Controller
                              name="completes_tasks"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  disabled={loadRegistration}
                                  checked={!!props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Completes tasks in a timely manner"
                        />
                        <FormControlLabel
                          control={
                            <Controller
                              name="respects_authority"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  disabled={loadRegistration}
                                  checked={!!props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Respects authority"
                        />
                      </FormGroup>
                    </Box>
                    {checkboxList.length === 0 &&
                      isSubmitted &&
                      renderError("Please select at least one option.")}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                mx={() =>
                  (useMediaQuery(theme.breakpoints.up("lg")) && 35) || "unset"
                }
                mt={4}
              >
                <Typography sx={formTitle}>Authorized Pick Up</Typography>
              </Grid>

              <Grid
                container
                item
                xs={12}
                mx={() =>
                  (useMediaQuery(theme.breakpoints.up("lg")) && 35) || "unset"
                }
                spacing={2}
              >
                <Grid item xs={12}>
                  <Typography sx={font16} mt={3}>
                    Please list who may pick up your child from school (you may
                    add more authorized pick-ups later).
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="authorized_pick_up_1_first_name"
                    label="First Name"
                    error={!!errors?.authorized_pick_up_1_first_name}
                    fullWidth
                    {...register("authorized_pick_up_1_first_name", {
                      maxLength: maxLengthValidation(100),
                      disabled: loadRegistration,
                      required: {
                        value: authorizedPickUpRequired("1"),
                        message: requiredMessage,
                      },
                    })}
                  />
                  {errors.authorized_pick_up_1_first_name &&
                    renderError(errors.authorized_pick_up_1_first_name.message)}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="authorized_pick_up_1_last_name"
                    label="Last Name"
                    error={!!errors?.authorized_pick_up_1_last_name}
                    fullWidth
                    {...register("authorized_pick_up_1_last_name", {
                      maxLength: maxLengthValidation(100),
                      disabled: loadRegistration,
                      required: {
                        value: authorizedPickUpRequired("1"),
                        message: requiredMessage,
                      },
                    })}
                  />
                  {errors.authorized_pick_up_1_last_name &&
                    renderError(errors.authorized_pick_up_1_last_name.message)}
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    fullWidth
                    error={!!errors?.authorized_pick_up_1_relationship}
                  >
                    <InputLabel
                      id="authorized_pick_up_1_relationship"
                      shrink
                      sx={backgroundWhite}
                    >
                      Relationship to Student
                    </InputLabel>
                    <Controller
                      name="authorized_pick_up_1_relationship"
                      control={control}
                      defaultValue=""
                      rules={{ required: authorizedPickUpRequired("1") }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="authorized_pick_up_1_relationship"
                          id="authorized_pick_up_1_relationship"
                          value={value}
                          onChange={(e) => onChange(e)}
                          {...register("authorized_pick_up_1_relationship", {
                            disabled: loadRegistration,
                            required: {
                              value: authorizedPickUpRequired("1"),
                              message: requiredMessage,
                            },
                          })}
                        >
                          <MenuItem disabled value="" />
                          {guardiansList.map((guardian) => (
                            <MenuItem key={guardian.id} value={guardian.name}>
                              {guardian.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.authorized_pick_up_1_relationship &&
                      renderError(
                        errors.authorized_pick_up_1_relationship.message
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="authorized_pick_up_1_phone_number"
                    label="Phone Number"
                    error={!!errors?.authorized_pick_up_1_phone_number}
                    fullWidth
                    {...register("authorized_pick_up_1_phone_number", {
                      pattern: phoneNumberPattern,
                      disabled: loadRegistration,
                      required: {
                        value: authorizedPickUpRequired("1"),
                        message: requiredMessage,
                      },
                    })}
                  />
                  {(errors.authorized_pick_up_1_phone_number &&
                    renderError(
                      errors.authorized_pick_up_1_phone_number.message
                    )) ||
                    (errors.authorized_pick_up_1_phone_number &&
                      renderError(
                        errors.authorized_pick_up_1_phone_number.message
                      ))}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="authorized_pick_up_2_first_name"
                    label="First Name"
                    error={!!errors?.authorized_pick_up_2_first_name}
                    fullWidth
                    {...register("authorized_pick_up_2_first_name", {
                      maxLength: maxLengthValidation(100),
                      required: {
                        value: authorizedPickUpRequired("2"),
                        message: requiredMessage,
                      },
                    })}
                  />
                  {errors.authorized_pick_up_2_first_name &&
                    renderError(errors.authorized_pick_up_2_first_name.message)}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="authorized_pick_up_2_last_name"
                    label="Last Name"
                    error={!!errors?.authorized_pick_up_2_last_name}
                    fullWidth
                    {...register("authorized_pick_up_2_last_name", {
                      maxLength: maxLengthValidation(100),
                      required: {
                        value: authorizedPickUpRequired("2"),
                        message: requiredMessage,
                      },
                    })}
                  />
                  {errors.authorized_pick_up_2_last_name &&
                    renderError(errors.authorized_pick_up_2_last_name.message)}
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    fullWidth
                    error={!!errors?.authorized_pick_up_2_relationship}
                  >
                    <InputLabel
                      id="authorized_pick_up_2_relationship"
                      shrink
                      sx={backgroundWhite}
                    >
                      Relationship to Student
                    </InputLabel>
                    <Controller
                      name="authorized_pick_up_2_relationship"
                      control={control}
                      defaultValue=""
                      rules={{ required: authorizedPickUpRequired("2") }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="authorized_pick_up_2_relationship"
                          id="authorized_pick_up_2_relationship"
                          value={value}
                          onChange={(e) => onChange(e)}
                          {...register("authorized_pick_up_2_relationship", {
                            disabled: loadRegistration,
                            required: {
                              value: authorizedPickUpRequired("2"),
                              message: requiredMessage,
                            },
                          })}
                        >
                          <MenuItem disabled value="" />
                          {guardiansList.map((guardian) => (
                            <MenuItem key={guardian.id} value={guardian.name}>
                              {guardian.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.authorized_pick_up_2_relationship &&
                      renderError(
                        errors.authorized_pick_up_2_relationship.message
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="authorized_pick_up_2_phone_number"
                    label="Phone Number"
                    error={!!errors?.authorized_pick_up_2_phone_number}
                    fullWidth
                    {...register("authorized_pick_up_2_phone_number", {
                      pattern: phoneNumberPattern,
                      required: {
                        value: authorizedPickUpRequired("2"),
                        message: requiredMessage,
                      },
                    })}
                  />
                  {(errors.authorized_pick_up_2_phone_number &&
                    renderError(
                      errors.authorized_pick_up_2_phone_number.message
                    )) ||
                    (errors.authorized_pick_up_2_phone_number &&
                      renderError(
                        errors.authorized_pick_up_2_phone_number.message
                      ))}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                mx={() =>
                  (useMediaQuery(theme.breakpoints.up("lg")) && 35) || "unset"
                }
                mt={4}
              >
                <Typography sx={formTitle}>Permission and Consent*</Typography>
              </Grid>

              <Grid
                container
                item
                xs={12}
                mx={() =>
                  (useMediaQuery(theme.breakpoints.up("lg")) && 35) || "unset"
                }
                spacing={2}
              >
                <Grid item xs={12} md={4}>
                  <Typography sx={font16} mr={2}>
                    <b>Field Trip Permission - </b> I give permission for my
                    child to take part in all school sponsored off-campus
                    activities/field trips. I understand that I will receive
                    advanced notice of each activity 48 hours prior. I also
                    understand that I may, at any time, revoke this permission
                    for a specific activity.
                  </Typography>
                  <FormControl sx={minWidthFit}>
                    <Controller
                      name="field_trip_permission"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: requiredValidation,
                      }}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          {...field}
                          onChange={(event, value) => field.onChange(value)}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>Yes</Typography>
                              </Box>
                            }
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>No</Typography>
                              </Box>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                    {errors.field_trip_permission &&
                      renderError(errors.field_trip_permission.message)}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography sx={font16} mr={2}>
                    <b>Photo Consent - </b> As a parent or guardian of this
                    student, I hereby consent to the use of
                    photographs/videotape taken during the course of the school
                    year for publicity, promotional and/or educational purposes
                    (including publications, presentation or broadcast via
                    newspaper, internet or other media sources). I do this with
                    full knowledge and consent and waive all claims for
                    compensation for use, or for damages.
                  </Typography>
                  <FormControl sx={minWidthFit}>
                    <Controller
                      name="photo_consent"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: requiredValidation,
                      }}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          {...field}
                          onChange={(event, value) => field.onChange(value)}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>Yes</Typography>
                              </Box>
                            }
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio disabled={loadRegistration} />}
                            sx={alignStart}
                            label={
                              <Box mt={1}>
                                <Typography>No</Typography>
                              </Box>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                    {errors.photo_consent &&
                      renderError(errors.photo_consent.message)}
                  </FormControl>
                </Grid>
                {!loadRegistration && (
                  <>
                    <Grid item xs={12} textAlign="center">
                      <Typography sx={font32}>Thank you!</Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center" mt={2}>
                      <GenericSubmitButton
                        text="Submit Application"
                        sx={submitButton}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Snackbar
            open={snackBarOpen.open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={snackBarOpen.severity}>
              {snackBarOpen.message}
            </Alert>
          </Snackbar>
        </>
      )}
    </form>
  );
}

export default function RegistrationForm({
  loadRegistration = false,
  schoolYear = null,
  needAuthorization = true,
}) {
  const { hasPermission, hasAnyPermissionType } =
    useContext(PermissionsContext);
  const viewPermission = hasAnyPermissionType(APP_PERMISSIONS.ADMISSIONS);
  const managePermission = hasPermission(
    APP_PERMISSIONS.ADMISSIONS,
    PERMISSION_TYPES.MANAGE
  );
  return needAuthorization ? (
    <Authorize permission={viewPermission}>
      <RegistrationFormRender
        loadRegistration={loadRegistration}
        schoolYear={schoolYear}
        managePermission={managePermission}
      />
    </Authorize>
  ) : (
    <RegistrationFormRender
      loadRegistration={loadRegistration}
      schoolYear={schoolYear}
      managePermission={managePermission}
    />
  );
}
