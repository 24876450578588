import React from "react";
import { Route } from "react-router-dom";
import Admissions from "../views/Admissions";
import Registrations from "../components/Admissions/Setup/Registrations";
import RegistrationForm from "../components/Admissions/Setup/RegistrationForm";
import Students from "../components/Admissions/Students/Students";
import Setup from "../components/Admissions/Setup/Setup";
import Inquiries from "../components/Admissions/Students/Inquiries";
import Application from "../components/Admissions/Setup/Application";
import FormBuilder from "../components/Admissions/Setup/FormBuilder/FormBuilder";
import Data from "../components/Admissions/Setup/FormBuilder/Components/FieldSet/Data";
import FieldTypes from "../components/Admissions/Setup/FormBuilder/Components/FieldSet/FieldTypes";
import Layout from "../components/Admissions/Setup/FormBuilder/Components/FieldSet/Layout";
import Response from "../components/Admissions/Setup/FormBuilder/Components/FieldSet/Response";
import { FormBuilderContextProvider } from "../components/Admissions/Setup/FormBuilder/FormBuilderContext";
import AdmissionProcesses from "../components/Admissions/Setup/AdmissionProcesses";
import AdmissionProcessBuilder from "../components/Admissions/Setup/AdmissionProcessBuilder/AdmissionProcessBuilder";
import FormTab from "../components/Admissions/Setup/AdmissionProcessBuilder/ProcessFieldSet/FormTab";
import FilesTab from "../components/Admissions/Setup/AdmissionProcessBuilder/ProcessFieldSet/FilesTab";
import FeeTab from "../components/Admissions/Setup/AdmissionProcessBuilder/ProcessFieldSet/FeeTab";
import TaskTab from "../components/Admissions/Setup/AdmissionProcessBuilder/ProcessFieldSet/TaskTab";
import EventTab from "../components/Admissions/Setup/AdmissionProcessBuilder/ProcessFieldSet/EventTab";
import AlertTab from "../components/Admissions/Setup/AdmissionProcessBuilder/ProcessFieldSet/AlertTab";
import FormsList from "../components/Admissions/Setup/FormsList/FormsList";
import StudentDetails from "../components/Admissions/Students/StudentDetails/StudentDetails";
import ProcessOverview from "../components/Admissions/Students/StudentDetails/ProcessOverview";
import { ProcessBuilderContextProvider } from "../components/Admissions/Setup/AdmissionProcessBuilder/ProcessBuilderContext";
import InProcess from "../components/Admissions/Students/InProcess";
import InReview from "../components/Admissions/Students/InReview";
import WaitingList from "../components/Admissions/Students/WaitingList";
import Accepted from "../components/Admissions/Students/Accepted";
import Forms from "../components/Admissions/Students/StudentDetails/Forms";
import Files from "../components/Admissions/Students/StudentDetails/Files";
import Notes from "../components/Admissions/Students/StudentDetails/Notes";
import ActivityLog from "../components/Admissions/Students/StudentDetails/ActivityLog";
import Approvals from "../components/Admissions/ApprovalsAndActions/Approvals";
import Actions from "../components/Admissions/ApprovalsAndActions/Actions";
import Enrollments from "../components/Admissions/Enrollments/Enrollments";
import EnrolledStudents from "../components/Admissions/Enrollments/EnrolledStudents";
import UnenrolledStudents from "../components/Admissions/Enrollments/UnenrolledStudents";
import DataView from "../components/Admissions/Students/StudentDetails/DataView";
import WelcomeMessage from "../components/Admissions/Setup/WelcomeMessage";
import Dashboard from "../components/Admissions/Dashboard/Dashboard";

export default [
  <Route path="/school/:school_id/admissions" element={<Admissions />} key={0}>
    <Route
      path="/school/:school_id/admissions/registrations"
      element={<Registrations />}
      key={1}
    />
    <Route key={2} path="setup" element={<Setup />}>
      <Route key={1} path="inquiries" element={<Inquiries />} />
      <Route key={2} path="in-process" element={<InProcess />} />
      <Route key={3} path="application" element={<Application />} />
      <Route key={4} path="registrations" element={<Registrations />} />
      <Route key={5} path="processes" element={<AdmissionProcesses />} />
      <Route
        key={4}
        path="registrations/:id"
        element={<RegistrationForm loadRegistration />}
      />
      <Route key={6} path="forms" element={<FormsList />} />
      <Route
        key={7}
        path="form-builder"
        element={
          <FormBuilderContextProvider>
            <FormBuilder />
          </FormBuilderContextProvider>
        }
      >
        <Route key={1} path="data" element={<Data />} />
        <Route key={2} path="field-types" element={<FieldTypes />} />
        <Route key={3} path="layout" element={<Layout />} />
        <Route key={4} path="response" element={<Response />} />
      </Route>
      <Route
        key={8}
        path="process-builder"
        element={
          <ProcessBuilderContextProvider>
            <AdmissionProcessBuilder />
          </ProcessBuilderContextProvider>
        }
      >
        <Route key={1} path="form" element={<FormTab />} />
        <Route key={2} path="files" element={<FilesTab />} />
        <Route key={3} path="fee" element={<FeeTab />} />
        <Route key={4} path="task" element={<TaskTab />} />
        <Route key={5} path="event" element={<EventTab />} />
        <Route key={6} path="alert" element={<AlertTab />} />
      </Route>
      <Route key={9} path="welcome-message" element={<WelcomeMessage />} />
    </Route>
    <Route
      key={3}
      path="registrations/:id"
      element={<RegistrationForm loadRegistration />}
    />
    <Route key={4} path="students" element={<Students />}>
      <Route path="inquiries" element={<Inquiries />} key={1} />
      <Route path="in-process" element={<InProcess />} key={2} />
      <Route path="in-review" element={<InReview />} key={3} />
      <Route path="waiting-list" element={<WaitingList />} key={4} />
      <Route path="accepted" element={<Accepted />} key={5} />
    </Route>
    <Route key={5} path="student-details" element={<StudentDetails />}>
      <Route key={1} path="process-overview" element={<ProcessOverview />} />
      <Route key={2} path="forms" element={<Forms />} />
      <Route key={3} path="files" element={<Files />} />
      <Route key={4} path="notes" element={<Notes />} />
      <Route key={5} path="activity-log" element={<ActivityLog />} />
    </Route>
    <Route key={6} path="approvals" element={<Approvals />} />
    <Route key={7} path="actions" element={<Actions />} />
    <Route key={8} path="enrollments" element={<Enrollments />}>
      <Route path="enrolled-students" element={<EnrolledStudents />} key={1} />
      <Route
        path="unenrolled-students"
        element={<UnenrolledStudents />}
        key={2}
      />
    </Route>
    <Route key={9} path="dashboard" element={<Dashboard />} />
  </Route>,
  <Route
    key={1}
    path="/school/:school_id/data-view"
    element={
      <FormBuilderContextProvider>
        <DataView />
      </FormBuilderContextProvider>
    }
  />,
];
