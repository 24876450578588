import React, { createContext, useState, useMemo } from "react";

export const QuickBarContext = createContext({
  postTermGrades: false,
  postPKTermGrades: false,
  postSkills: false,
  postSdSkills: false,
  downloadReportCards: false,
  publishReportCards: false,
  unpublishReportCards: false,
  distributeReportCards: false,
  addEmergencyContacts: false,
  addParents: false,
  deleteConversation: false,
  addStudentFiles: false,
  addStudentPortfolioFiles: false,
  addStudentMedicalRecord: false,
  addStudentEvent: false,
  printReport: false,
  assignmentDrawer: false,
  syncAssignments: false,
  hasGoogleAssignments: false,
  syncStaffs: false,
  syncStudents: false,
  addSecurityGroups: false,
  addGPAScale: false,
  addGroups: false,
  addTemporaryStaff: false,
  isAutoSaving: false,
  createNewProcess: false,
  createNewForm: false,
  reEnrollStudents: false,
  addNewInquiry: false,
  addProcessNotes: false,
  fetchInquiries: false,
  actionFired: false,
  loadTranscripts: false,
});

export function QuickBarProvider({ children }) {
  const [postPKTermGrades, setPostPKTermGrades] = useState(false);
  const [postTermGrades, setPostTermGrades] = useState(false);
  const [postSkills, setPostSkills] = useState(false);
  const [postSdSkills, setPostSdSkills] = useState(false);
  const [downloadReportCards, setDownloadReportCards] = useState(false);
  const [publishReportCards, setPublishReportCards] = useState(false);
  const [unpublishReportCards, setUnpublishReportCards] = useState(false);
  const [distributeReportCards, setDistributeReportCards] = useState(false);
  const [addEmergencyContacts, setAddEmergencyContacts] = useState(false);
  const [addParents, setAddParents] = useState(false);
  const [deleteConversation, setDeleteConversation] = useState(false);
  const [addStudentFiles, setAddStudentFiles] = useState(false);
  const [addStudentPortfolioFiles, setAddStudentPortfolioFiles] =
    useState(false);
  const [addStudentMedicalRecord, setAddStudentMedicalRecord] = useState(false);
  const [addStudentEvent, setAddStudentEvent] = useState(false);
  const [printReport, setPrintReport] = useState(false);
  const [assignmentDrawer, setAssignmentDrawer] = useState(false);
  const [addSecurityGroups, setAddSecurityGroups] = useState(false);
  const [addGPAScale, setAddGPAScale] = useState(false);
  const [addGroups, setAddGroups] = useState(false);
  const [syncAssignments, setSyncAssignments] = useState(false);
  const [hasGoogleAssignments, setHasGoogleAssignments] = useState(false);
  const [syncStaffs, setSyncStaffs] = useState(false);
  const [syncStudents, setSyncStudents] = useState(false);
  const [addTemporaryStaff, setAddTemporaryStaff] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [createNewProcess, setCreateNewProcess] = useState(false);
  const [createNewForm, setCreateNewForm] = useState(false);
  const [reEnrollStudents, setReEnrollStudents] = useState(false);
  const [addNewInquiry, setAddNewInquiry] = useState(false);
  const [fetchInquiries, setFetchInquiries] = useState(false);
  const [addProcessNotes, setAddProcessNotes] = useState(false);
  const [loadTranscripts, setLoadTranscripts] = useState(false);

  const firePostTermGrades = () => {
    setPostTermGrades(true);
  };
  const cleanFirePostTermGrades = () => {
    setPostTermGrades(false);
  };
  const firePostPKTermGrades = () => {
    setPostPKTermGrades(true);
  };
  const cleanFirePostPKTermGrades = () => {
    setPostPKTermGrades(false);
  };
  const firePostSkills = () => {
    setPostSkills(true);
  };
  const cleanFirePostSkills = () => {
    setPostSkills(false);
  };
  const firePostSdSkills = () => {
    setPostSdSkills(true);
  };
  const cleanFirePostSdSkills = () => {
    setPostSdSkills(false);
  };
  const fireDownloadReportCards = () => {
    setDownloadReportCards(true);
  };
  const firePublishReportCards = () => {
    setPublishReportCards(true);
  };
  const cleanPublishReportCards = () => {
    setPublishReportCards(false);
  };
  const fireUnpublishReportCards = () => {
    setUnpublishReportCards(true);
  };
  const cleanUnpublishReportCards = () => {
    setUnpublishReportCards(false);
  };
  const fireDistributeReportCards = () => {
    setDistributeReportCards(true);
  };
  const cleanDownloadReportCards = () => {
    setDownloadReportCards(false);
  };
  const cleanDistributeReportCards = () => {
    setDistributeReportCards(false);
  };
  const showAddEmergencyContacts = () => {
    setAddEmergencyContacts(true);
  };
  const hideAddEmergencyContacts = () => {
    setAddEmergencyContacts(false);
  };
  const showAddParents = () => {
    setAddParents(true);
  };
  const hideAddParents = () => {
    setAddParents(false);
  };
  const fireDeleteConversation = () => {
    setDeleteConversation(true);
  };
  const cleanDeleteConversation = () => {
    setDeleteConversation(false);
  };
  const fireAddStudentFiles = () => {
    setAddStudentFiles(true);
  };
  const fireAddStudentPortfolioFiles = () => {
    setAddStudentPortfolioFiles(true);
  };
  const cleanAddStudentFiles = () => {
    setAddStudentFiles(false);
  };
  const cleanAddStudentPortfolioFiles = () => {
    setAddStudentPortfolioFiles(false);
  };
  const fireAddStudentMedicalRecord = () => {
    setAddStudentMedicalRecord(true);
  };
  const cleanAddStudentMedicalRecord = () => {
    setAddStudentMedicalRecord(false);
  };
  const fireAddStudentEvent = () => {
    setAddStudentEvent(true);
  };
  const cleanAddStudentEvent = () => {
    setAddStudentEvent(false);
  };
  const fireAssignmentDrawer = () => {
    setAssignmentDrawer(true);
  };
  const cleanAssignmentDrawer = () => {
    setAssignmentDrawer(false);
  };
  const fireSyncAssignments = () => {
    setSyncAssignments(true);
  };
  const cleanSyncAssignments = () => {
    setSyncAssignments(false);
  };
  const fireGoogleAssignments = () => {
    setHasGoogleAssignments(true);
  };
  const cleanGoogleAssignments = () => {
    setHasGoogleAssignments(false);
  };
  const fireSyncStaffs = () => {
    setSyncStaffs(true);
  };
  const cleanSyncStaffs = () => {
    setSyncStaffs(false);
  };

  const fireSyncStudents = () => {
    setSyncStudents(true);
  };
  const cleanSyncStudents = () => {
    setSyncStudents(false);
  };

  const firePrintReport = () => {
    setPrintReport(true);
  };
  const cleanPrintReport = () => {
    setPrintReport(false);
  };

  const showAddSecurityGroups = () => {
    setAddSecurityGroups(true);
  };

  const hideAddSecurityGroups = () => {
    setAddSecurityGroups(false);
  };

  const showAddGPAScale = () => {
    setAddGPAScale(true);
  };

  const hideAddGPAScale = () => {
    setAddGPAScale(false);
  };

  const showAddGroups = () => {
    setAddGroups(true);
  };

  const hideAddGroups = () => {
    setAddGroups(false);
  };

  const fireAddTemporaryStaff = () => {
    setAddTemporaryStaff(true);
  };

  const cleanAddTemporaryStaff = () => {
    setAddTemporaryStaff(false);
  };

  const fireAutoSaving = () => {
    setIsAutoSaving(true);
  };

  const cleanAutoSaving = () => {
    setIsAutoSaving(false);
  };
  const fireCreateNewProcess = () => {
    setCreateNewProcess(true);
  };

  const cleanCreateNewProcess = () => {
    setCreateNewProcess(false);
  };

  const fireCreateNewForm = () => {
    setCreateNewForm(true);
  };

  const cleanCreateNewForm = () => {
    setCreateNewForm(false);
  };

  const fireReEnrollStudents = () => {
    setReEnrollStudents(true);
  };

  const cleanReEnrollStudents = () => {
    setReEnrollStudents(false);
  };

  const fireAddNewInquiry = () => {
    setAddNewInquiry(true);
  };

  const cleanAddNewInquiry = () => {
    setAddNewInquiry(false);
  };

  const fireAddProcessNotes = () => {
    setAddProcessNotes(true);
  };

  const cleanAddProcessNotes = () => {
    setAddProcessNotes(false);
  };

  const fireFetchInquiries = () => {
    setFetchInquiries(true);
  };

  const cleanFetchInquiries = () => {
    setFetchInquiries(false);
  };

  const fireLoadTranscripts = () => {
    setLoadTranscripts(true);
  };

  const cleanLoadTranscripts = () => {
    setLoadTranscripts(false);
  };

  const actionsList = [
    postTermGrades,
    postPKTermGrades,
    postSkills,
    postSdSkills,
    downloadReportCards,
    publishReportCards,
    unpublishReportCards,
    distributeReportCards,
    addEmergencyContacts,
    addParents,
    deleteConversation,
    addStudentFiles,
    addStudentPortfolioFiles,
    addStudentMedicalRecord,
    addStudentEvent,
    printReport,
    assignmentDrawer,
    syncAssignments,
    hasGoogleAssignments,
    syncStaffs,
    syncStudents,
    addSecurityGroups,
    addGPAScale,
    addGroups,
    addTemporaryStaff,
    isAutoSaving,
    createNewProcess,
    createNewForm,
    reEnrollStudents,
    addNewInquiry,
    addProcessNotes,
    fetchInquiries,
    loadTranscripts,
  ];

  const actionFired = actionsList.some((action) => action);

  const contextValue = useMemo(
    () => ({
      firePostTermGrades,
      cleanFirePostTermGrades,
      firePostPKTermGrades,
      cleanFirePostPKTermGrades,
      firePostSkills,
      cleanFirePostSkills,
      firePostSdSkills,
      cleanFirePostSdSkills,
      fireDownloadReportCards,
      firePublishReportCards,
      cleanPublishReportCards,
      fireDistributeReportCards,
      cleanDownloadReportCards,
      cleanDistributeReportCards,
      fireUnpublishReportCards,
      cleanUnpublishReportCards,
      fireDeleteConversation,
      cleanDeleteConversation,
      postTermGrades,
      postPKTermGrades,
      postSkills,
      postSdSkills,
      downloadReportCards,
      publishReportCards,
      unpublishReportCards,
      distributeReportCards,
      showAddEmergencyContacts,
      showAddParents,
      hideAddEmergencyContacts,
      hideAddParents,
      addEmergencyContacts,
      addParents,
      deleteConversation,
      fireAddStudentFiles,
      cleanAddStudentFiles,
      addStudentFiles,
      fireAddStudentPortfolioFiles,
      cleanAddStudentPortfolioFiles,
      addStudentPortfolioFiles,
      fireAddStudentMedicalRecord,
      cleanAddStudentMedicalRecord,
      addStudentMedicalRecord,
      fireAddStudentEvent,
      cleanAddStudentEvent,
      addStudentEvent,
      firePrintReport,
      cleanPrintReport,
      printReport,
      assignmentDrawer,
      fireAssignmentDrawer,
      cleanAssignmentDrawer,
      fireSyncAssignments,
      cleanSyncAssignments,
      syncAssignments,
      showAddSecurityGroups,
      showAddGPAScale,
      hideAddGPAScale,
      addGPAScale,
      addSecurityGroups,
      hideAddSecurityGroups,
      fireGoogleAssignments,
      hasGoogleAssignments,
      cleanGoogleAssignments,
      fireSyncStaffs,
      cleanSyncStaffs,
      syncStaffs,
      fireSyncStudents,
      cleanSyncStudents,
      syncStudents,
      addTemporaryStaff,
      fireAddTemporaryStaff,
      cleanAddTemporaryStaff,
      isAutoSaving,
      fireAutoSaving,
      cleanAutoSaving,
      showAddGroups,
      addGroups,
      hideAddGroups,
      createNewProcess,
      createNewForm,
      reEnrollStudents,
      fireCreateNewProcess,
      cleanCreateNewProcess,
      fireCreateNewForm,
      cleanCreateNewForm,
      fireReEnrollStudents,
      cleanReEnrollStudents,
      addNewInquiry,
      fireAddNewInquiry,
      cleanAddNewInquiry,
      addProcessNotes,
      fetchInquiries,
      fireAddProcessNotes,
      cleanAddProcessNotes,
      fireFetchInquiries,
      cleanFetchInquiries,
      actionFired,
      loadTranscripts,
      fireLoadTranscripts,
      cleanLoadTranscripts,
    }),
    actionsList
  );

  return (
    <QuickBarContext.Provider value={contextValue}>
      {children}
    </QuickBarContext.Provider>
  );
}
