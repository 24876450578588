import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Stack,
  Box,
  Button,
  ButtonBase,
  Chip,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useFormContext } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import lookupService from "../../../../service/lookupService";
import { bgWhite, danger } from "../../../sharedStyles";
import {
  addClassificationButton,
  chipContainer,
  chipStyles,
} from "./StaffInfoAccount.style";
import countriesService from "../../../../service/countriesService";
import moment from "../../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../../utils/constants/dateTimeFormats";

function Demographics({ teacher, managePermission }) {
  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    getValues,
  } = useFormContext();
  const [genders, setGenders] = useState([]);
  const [ethnicities, setEthnicities] = useState([]);
  const [techerTitles, setTeacherTitles] = useState([]);
  const [expirationDate, setExpirationDate] = useState(
    teacher.expiration_date || ""
  );
  const [denominationChecked, setDemoninationChecked] = useState(
    Boolean(teacher.is_seventh_day_adventist)
  );
  const [stateCertificationChecked, setStateCerficationChecked] = useState(
    Boolean(teacher.has_state_certification)
  );
  const [titleList, setTitleList] = useState(
    teacher.state_certification_title?.split(",") || []
  );
  const [states, setStates] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [classifications, setClassifications] = useState([]);

  const [fteClassifications, setFteClassifications] = useState(
    teacher.fte_classifications?.length
      ? teacher.fte_classifications
      : [{ fte_classification: "", equivalent: "" }]
  );

  useEffect(() => {
    setValue("certification_state", teacher.certification_state, {
      shouldDirty: true,
    });
    Promise.all([
      lookupService.fetchGenders(),
      lookupService.fetchSchoolEthnicities(),
      countriesService.fetchAllStates(),
      lookupService.fetchDegrees(),
      lookupService.fetchFteClassifications(),
      lookupService.fetchTeacherTitles(),
    ]).then((response) => {
      setGenders(response[0].data.data);
      setEthnicities(response[1].data.data);
      setStates(response[2].data);
      setDegrees(response[3].data.data);
      setClassifications(response[4].data.data);
      setTeacherTitles(response[5].data.data);
    });
  }, [teacher]);

  useEffect(() => {
    if (!stateCertificationChecked) {
      setExpirationDate(null);
      setValue("expiration_date", null, { shouldDirty: true });
      setValue("certification_state", null, { shouldDirty: true });
    }
    if (!denominationChecked) {
      setValue("home_church", null, { shouldDirty: true });
    }
    if (managePermission)
      setTimeout(() => {
        trigger("expiration_date");
        trigger("certification_state");
        trigger("home_church");
      });
  }, [stateCertificationChecked, denominationChecked]);

  const onExpirationDateHandleChange = (date) => {
    if (date) {
      setExpirationDate(date);
      setValue("expiration_date", date.format("MM/DD/YYYY"), {
        shouldDirty: true,
      });
      trigger("expiration_date");
    }
  };

  const validateEquivalent = () => {
    const equivalentSum = fteClassifications.reduce(
      (acc, classification) => acc + Number(classification.equivalent),
      0
    );
    if (equivalentSum > 1)
      return fteClassifications.length > 1
        ? "Sum of all equivalent should not be greater than 1."
        : "Equivalent should not be greater than 1.";
    if (equivalentSum <= 0)
      return fteClassifications.length > 1
        ? "Sum of all equivalent should be greater than 0."
        : "Equivalent should be greater than 0.";
    return true;
  };

  return (
    teacher && (
      <Grid item xs={12} textAlign="center" mt={4}>
        <Grid item xs={12} my={1}>
          <Stack direction="column">
            <Box ml={3} mb={2} key="gender">
              <FormControl fullWidth disabled={!managePermission}>
                <InputLabel id="genderLabel">Gender *</InputLabel>
                <Select
                  labelId="genderLabel"
                  id="gender"
                  label="Gender *"
                  placeholder="Select"
                  defaultValue={teacher.gender || ""}
                  error={!!errors?.gender}
                  {...register("gender", {
                    required: "This field is required.",
                  })}
                >
                  {genders.map((gender) => (
                    <MenuItem key={gender.id} value={gender.name}>
                      <Typography textAlign="left">{gender.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
                {errors.gender && (
                  <Typography sx={danger} align="left">
                    {errors.gender.message}
                  </Typography>
                )}
              </FormControl>
            </Box>
            <Box ml={3} mb={2} key="ethnicity">
              <FormControl fullWidth disabled={!managePermission}>
                <InputLabel id="ethnicityLabel">Ethnicity *</InputLabel>
                <Select
                  labelId="ethnicityLabel"
                  id="ethnicity"
                  label="Ethnicity *"
                  defaultValue={teacher.ethnicity || ""}
                  error={!!errors?.ethnicity}
                  {...register("ethnicity", {
                    required: "This field is required.",
                  })}
                >
                  {ethnicities.map((ethnicity) => (
                    <MenuItem key={ethnicity.id} value={ethnicity.name}>
                      <Typography textAlign="left">{ethnicity.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
                {errors.ethnicity && (
                  <Typography sx={danger} align="left">
                    {errors.ethnicity.message}
                  </Typography>
                )}
              </FormControl>
            </Box>
            <Box ml={3} mb={2} key="highest_degree">
              <FormControl fullWidth disabled={!managePermission}>
                <InputLabel id="highestDegreeLabel">Highest Degree</InputLabel>
                <Select
                  labelId="highestDegreeLabel"
                  id="highest_degree"
                  label="Highest Degree"
                  defaultValue={teacher.highest_degree || ""}
                  {...register("highest_degree")}
                >
                  {degrees.map((degree) => (
                    <MenuItem key={degree.id} value={degree.name}>
                      <Typography textAlign="left">{degree.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid container item sm={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => {
                          setDemoninationChecked(event.target.checked);
                          setValue(
                            "is_seventh_day_adventist",
                            event.target.checked,
                            { shouldDirty: true }
                          );
                        }}
                        defaultChecked={denominationChecked}
                      />
                    }
                    label="Is Seventh-day Adventist"
                  />
                </FormGroup>
              </Grid>
              <Grid container item sm={11} xs={12} ml="auto">
                <FormControl fullWidth disabled={!managePermission}>
                  <TextField
                    id="homeChurch"
                    label="Home Church (membership)"
                    defaultValue={teacher.home_church || ""}
                    disabled={!denominationChecked}
                    required={denominationChecked}
                    error={!!errors.home_church}
                    {...register("home_church", {
                      required:
                        denominationChecked && "This field is required.",
                    })}
                  />
                </FormControl>
                {errors.home_church && (
                  <Typography sx={danger}>
                    {errors.home_church.message}
                  </Typography>
                )}
              </Grid>
              <Grid container item sm={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => {
                          setStateCerficationChecked(event.target.checked);
                          setValue(
                            "has_state_certification",
                            event.target.checked,
                            { shouldDirty: true }
                          );
                        }}
                        defaultChecked={stateCertificationChecked}
                      />
                    }
                    label="Has State Certification"
                  />
                </FormGroup>
              </Grid>
              <Grid container item sm={11} xs={12} ml="auto" mb={2}>
                <FormControl
                  fullWidth
                  required={stateCertificationChecked}
                  disabled={!stateCertificationChecked || !managePermission}
                >
                  <InputLabel id="certificationStateLabel">
                    Certification State
                  </InputLabel>
                  <Select
                    id="certificationState"
                    labelId="certificationStateLabel"
                    label="Certification State"
                    value={getValues("certification_state") || ""}
                    required={stateCertificationChecked}
                    error={!!errors?.certification_state}
                    {...register("certification_state", {
                      required:
                        stateCertificationChecked && "This field is required.",
                    })}
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.name}>
                        <Typography textAlign="left">{state.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.certification_state && (
                  <Typography sx={danger}>
                    {errors.certification_state.message}
                  </Typography>
                )}
              </Grid>
              <Grid container item sm={11} xs={12} ml="auto" mb={2}>
                <DesktopDatePicker
                  id="expirationDate"
                  label="Expiration Date"
                  onChange={onExpirationDateHandleChange}
                  value={
                    moment
                      .utc(expirationDate)
                      .format(dateTimeFormats.MMDDYYYY)
                      .toString() || ""
                  }
                  disabled={!stateCertificationChecked || !managePermission}
                  inputFormat="MM/DD/YYYY"
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={!!errors?.expiration_date}
                      {...params}
                      required={stateCertificationChecked}
                      {...register("expiration_date", {
                        required:
                          stateCertificationChecked &&
                          "This field is required.",
                        pattern: {
                          value:
                            /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                          message: "Invalid date.",
                        },
                      })}
                    />
                  )}
                />
                {errors.expiration_date && (
                  <Typography sx={danger}>
                    {errors.expiration_date.message}
                  </Typography>
                )}
              </Grid>
              <FormControl fullWidth disabled={!managePermission}>
                <InputLabel id="certificationTitleLabel">Title</InputLabel>
                <Select
                  labelId="certificationTitleLabel"
                  multiple
                  id="state_certification_title"
                  label="Title"
                  error={!!errors?.state_certification_title}
                  {...register("state_certification_title", {
                    required: (v) => {
                      if (v.length <= 0) {
                        return "This field is required.";
                      }
                      return true;
                    },
                  })}
                  onChange={(event) => {
                    setTitleList(event.target.value);
                    setValue(
                      "state_certification_title",
                      event.target.value.join(","),
                      {
                        shouldDirty: true,
                      }
                    );
                    trigger("state_certification_title");
                  }}
                  value={titleList}
                  renderValue={(selected) => (
                    <Box sx={chipContainer}>
                      {selected.map((value, index) => (
                        <Chip
                          key={value}
                          sx={chipStyles}
                          label={value}
                          deleteIcon={
                            <CancelOutlined
                              sx={{ color: "black !important" }}
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                          onDelete={() => {
                            const newValues = [...titleList];
                            newValues.splice(index, 1);
                            setTitleList(newValues);
                            setValue(
                              "state_certification_title",
                              newValues.join(","),
                              {
                                shouldDirty: true,
                              }
                            );
                            trigger("state_certification_title");
                          }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {techerTitles.map((teacherTitle) => (
                    <MenuItem key={teacherTitle.id} value={teacherTitle.name}>
                      <Typography textAlign="left">
                        {teacherTitle.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid textAlign="left" item sm={12} my={2}>
                {errors.state_certification_title &&
                  errors.state_certification_title.type === "required" && (
                    <Typography sx={danger}>This field is required.</Typography>
                  )}
              </Grid>
              <Box mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="one_roster_role" shrink sx={bgWhite}>
                    Role
                  </InputLabel>
                  <Select
                    labelId="one_roster_role"
                    id="one_roster_role"
                    defaultValue={teacher.one_roster_role}
                    {...register("one_roster_role")}
                    sx={{ textAlign: "left" }}
                  >
                    <MenuItem value="teacher">Teacher</MenuItem>
                    <MenuItem value="aide">Aide</MenuItem>
                    <MenuItem value="administrator">Administrator</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Grid textAlign="left" item sm={12} my={2}>
                <Typography variant="profileTitle">
                  FTE Classification
                </Typography>
              </Grid>
              {fteClassifications.map((item, index) => (
                <Grid
                  key={item.id}
                  item
                  sm={12}
                  direction="row"
                  container
                  spacing={2}
                  mt={2}
                >
                  <Grid item sm={6}>
                    <FormControl fullWidth disabled={!managePermission}>
                      <InputLabel
                        sx={{ backgroundColor: "white" }}
                        shrink
                        id="fteClassificationLabel"
                      >
                        FTE Classification *
                      </InputLabel>
                      <Select
                        labelId="fteClassificationLabel"
                        id="fteClassification"
                        label="FTE Classification *"
                        error={
                          !!errors?.fte_classifications_attributes &&
                          !!errors?.fte_classifications_attributes[index]
                            ?.fte_classification
                        }
                        value={item.fte_classification || ""}
                        {...register(
                          `fte_classifications_attributes[${index}].fte_classification`,
                          {
                            required:
                              "FTE classification and equivalent is required.",
                            onChange: (event) => {
                              const newFteClassification = [
                                ...fteClassifications,
                              ];
                              newFteClassification[index].fte_classification =
                                event.target.value;
                              setFteClassifications(newFteClassification);
                              trigger(
                                `fte_classifications_attributes[${index}].fte_classification`
                              );
                            },
                          }
                        )}
                      >
                        {classifications.map((classification) => (
                          <MenuItem
                            key={classification.id}
                            value={classification.name}
                          >
                            {classification.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={4.5}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label="Equivalent"
                      required
                      error={
                        !!errors?.fte_classifications_attributes &&
                        !!errors?.fte_classifications_attributes[index]
                          ?.equivalent
                      }
                      value={item.equivalent}
                      {...register(
                        `fte_classifications_attributes[${index}].equivalent`,
                        {
                          required:
                            "FTE classification and equivalent is required.",
                          pattern: {
                            value:
                              /^$|^0*(?:0\.(?!0+$)[0-9]{1,3}|[1-9][0-9]{0,2}(?:\.[0-9]{1,3})?)$/,
                            message:
                              "Please enter decimal value between 0.001 to 1",
                          },
                          validate: validateEquivalent,
                          onChange: (event) => {
                            const newFteClassification = [
                              ...fteClassifications,
                            ];
                            newFteClassification[index].equivalent =
                              event.target.value;
                            setFteClassifications(newFteClassification);
                            trigger(
                              `fte_classifications_attributes[${index}].equivalent`
                            );
                          },
                        }
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    sm={1.5}
                  >
                    <ButtonBase
                      onClick={() => {
                        if (fteClassifications.length > 1) {
                          const newFteClassification = [...fteClassifications];
                          newFteClassification.splice(index, 1);
                          setFteClassifications(newFteClassification);
                          setValue(
                            "fte_classifications_attributes",
                            newFteClassification,
                            { shouldDirty: true }
                          );
                        }
                      }}
                    >
                      <DeleteIcon />
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12}>
                    {errors?.fte_classifications_attributes &&
                      errors?.fte_classifications_attributes[index] && (
                        <Typography align="left" sx={danger}>
                          {errors?.fte_classifications_attributes[index]
                            .fte_classification?.message ||
                            (errors?.fte_classifications_attributes[index]
                              .equivalent?.type !== "validate" &&
                              errors?.fte_classifications_attributes[index]
                                .equivalent?.message)}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12}>
                {validateEquivalent() !== true && managePermission && (
                  <Typography align="left" sx={danger}>
                    {validateEquivalent()}
                  </Typography>
                )}
              </Grid>
              <Grid item sm={5} mt={2}>
                <Button
                  focusRipple
                  sx={addClassificationButton}
                  onClick={() => {
                    setFteClassifications([
                      ...fteClassifications,
                      { fte_classification: "", equivalent: "" },
                    ]);
                    trigger();
                  }}
                >
                  <Typography
                    style={{ textTransform: "none", fontSize: "13px" }}
                  >
                    Add Classification
                  </Typography>
                </Button>
              </Grid>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    )
  );
}

export default Demographics;
