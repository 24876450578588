import React, { useEffect, useState, useContext } from "react";
import {
  TableRow,
  TableCell,
  Typography,
  Button,
  TextField,
  Avatar,
  Link,
  Stack,
  Box,
} from "@mui/material";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  attendanceTableAction,
  attendanceTableBtn,
  attendanceTableName,
  stackStyle,
  attendanceRow,
  attendanceTableActionWeek,
  klassAttendanceBtn,
} from "./AttendanceTable.styles";
import attendanceService from "../../service/attendanceService";
import AutoSave from "../AutoSave";
import { px0, tableAvatar } from "../sharedStyles";
import StudentUserCard from "../StudentInfo/UserCard/StudentUserCard";
import dateTimeFormats from "../../utils/constants/dateTimeFormats";
import { PermissionsContext } from "../../context/PermissionsContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import PERMISSION_TYPES from "../../utils/constants/permission_types";

function ActionButton({
  student,
  currentDay,
  isAm,
  availableCodes,
  amCode,
  setAmCode,
  pmCode,
  setPmCode,
  amIndex,
  setAmIndex,
  pmIndex,
  setPmIndex,
  comment,
}) {
  const { hasPermission } = useContext(PermissionsContext);
  const managePermission = hasPermission(
    APP_PERMISSIONS.ATTENDANCE,
    PERMISSION_TYPES.MANAGE
  );

  const currentCode = () => (isAm ? amCode : pmCode);

  const areSameCode = () => amCode.index === 0 && pmCode.index === 0 && isAm;
  const activeCodes = [
    {
      code: "-",
      color: "#EBF0F1",
      index: 0,
    },
  ];
  const filteredActiveCodes = availableCodes
    .filter((c) => c.is_active)
    .map((c, index) => ({ ...c, index: index + 1 }));
  activeCodes.push(...filteredActiveCodes);

  const updateAmState = () => {
    setAmCode(activeCodes[amIndex]);
    setAmIndex((amIndex + 1) % activeCodes.length);
  };

  const updatePmState = () => {
    setPmCode(activeCodes[pmIndex]);
    setPmIndex((pmIndex + 1) % activeCodes.length);
  };

  const handleClick = async () => {
    if (areSameCode()) {
      updateAmState();
      updatePmState();

      const attendance = {
        student_id: student.id,
        am: activeCodes[amIndex].code,
        pm: activeCodes[pmIndex].code,
        date: currentDay.format("YYYY-MM-DD"),
        comment,
      };
      return attendanceService.recordAttendance(attendance);
    }

    if (isAm) {
      updateAmState();
    }
    if (!isAm) {
      updatePmState();
    }

    const attendance = {
      student_id: student.id,
      ...(isAm && {
        am: activeCodes[amIndex].code,
      }),
      ...(!isAm && {
        pm: activeCodes[pmIndex].code,
      }),
      date: currentDay.format("YYYY-MM-DD"),
      comment,
    };

    return attendanceService.recordAttendance(attendance);
  };

  return (
    <Button
      sx={attendanceTableBtn(currentCode().color)}
      {...(managePermission && {
        onClick: handleClick,
      })}
    >
      {currentCode().code}
    </Button>
  );
}

function ActionButtonKlass({
  student,
  klassCode,
  setKlassCode,
  availableCodes,
  klassCodeIndex,
  setKlassCodeIndex,
  comment,
  currentDay,
}) {
  const currentCode = klassCode;

  const activeCodes = [
    {
      code: "-",
      color: "#EBF0F1",
      index: 0,
    },
  ];

  const filteredActiveCodes = availableCodes
    .filter((c) => c.is_active)
    .map((c, index) => ({ ...c, index: index + 1 }));
  activeCodes.push(...filteredActiveCodes);

  const handleClick = async () => {
    setKlassCode(activeCodes[klassCodeIndex]);
    setKlassCodeIndex((klassCodeIndex + 1) % activeCodes.length);

    const attendance = {
      klass_attendance: true,
      klass_student_id: student.klass_student_id,
      klass_id: student.klass_id,
      code: activeCodes[klassCodeIndex].code,
      date: currentDay.format("YYYY-MM-DD"),
      comment,
    };

    return attendanceService.recordAttendance(attendance);
  };

  return (
    <Button
      sx={[attendanceTableBtn(currentCode.color), klassAttendanceBtn]}
      onClick={() => handleClick()}
    >
      {currentCode.code}
    </Button>
  );
}

function ButtonGroup({
  availableCodes,
  activeCode,
  student,
  currentDay,
  quickfill,
  setQuickfill,
  comment,
  isDayView,
  isClassView,
}) {
  const getAttendanceCode = (mode) => {
    const attendance =
      student.attendances?.find(
        (a) => a.date === currentDay.format("YYYY-MM-DD")
      ) || null;

    const getCode = (dayPart) =>
      availableCodes.find((c) =>
        attendance && attendance[dayPart]
          ? c.code === attendance[dayPart]
          : availableCodes[0]
      );

    if (mode === "am") {
      return getCode("am");
    }

    if (mode === "pm") {
      return getCode("pm");
    }

    return getCode("code");
  };

  const [amCode, setAmCode] = useState(getAttendanceCode("am"));
  const [pmCode, setPmCode] = useState(getAttendanceCode("pm"));
  const [amIndex, setAmIndex] = useState(activeCode.index);
  const [pmIndex, setPmIndex] = useState(activeCode.index);

  const [klassCode, setKlassCode] = useState(getAttendanceCode("code"));
  const [klassCodeIndex, setKlassCodeIndex] = useState(activeCode.index);

  useEffect(() => {
    if (quickfill && quickfill.date === currentDay.format("YYYY-MM-DD")) {
      if (isClassView) {
        if (klassCode.code === "-") {
          setKlassCode(quickfill.activeCode);
          setKlassCodeIndex(
            (quickfill.activeCode.index + 1) % availableCodes.length
          );
        }
      } else {
        if (amCode.code === "-") {
          setAmCode(quickfill.activeCode);
          setAmIndex((quickfill.activeCode.index + 1) % availableCodes.length);
        }

        if (pmCode.code === "-") {
          setPmCode(quickfill.activeCode);
          setPmIndex((quickfill.activeCode.index + 1) % availableCodes.length);
        }
      }
      setQuickfill(null);
    }
  }, [quickfill]);

  useEffect(() => {
    setAmIndex(activeCode.index);
    setPmIndex(activeCode.index);
    setKlassCodeIndex(activeCode.index);
  }, [activeCode]);

  useEffect(() => {
    if (isDayView) {
      setAmCode(getAttendanceCode("am"));
      setPmCode(getAttendanceCode("pm"));
    }
  }, [currentDay]);

  useEffect(() => {
    setAmCode(getAttendanceCode("am"));
    setPmCode(getAttendanceCode("pm"));
  }, [student]);

  return (
    <TableCell
      align="center"
      sx={isDayView ? attendanceTableAction : attendanceTableActionWeek}
    >
      {isClassView ? (
        <Box>
          <ActionButtonKlass
            student={student}
            klassCode={klassCode}
            setKlassCode={setKlassCode}
            klassCodeIndex={klassCodeIndex}
            setKlassCodeIndex={setKlassCodeIndex}
            availableCodes={availableCodes}
            comment={comment}
            currentDay={currentDay}
          />
        </Box>
      ) : (
        <Stack direction="row" spacing={1} sx={stackStyle}>
          <ActionButton
            availableCodes={availableCodes}
            isAm
            student={student}
            currentDay={currentDay}
            amCode={amCode}
            setAmCode={setAmCode}
            amIndex={amIndex}
            setAmIndex={setAmIndex}
            pmCode={pmCode}
            setPmCode={setPmCode}
            pmIndex={pmIndex}
            setPmIndex={setPmIndex}
            comment={comment}
          />
          <ActionButton
            availableCodes={availableCodes}
            isAm={false}
            student={student}
            currentDay={currentDay}
            amCode={amCode}
            setAmCode={setAmCode}
            amIndex={amIndex}
            setAmIndex={setAmIndex}
            pmCode={pmCode}
            setPmCode={setPmCode}
            pmIndex={pmIndex}
            setPmIndex={setPmIndex}
            comment={comment}
          />
        </Stack>
      )}
    </TableCell>
  );
}

export default function AttendanceTableRow({
  student,
  isDayView,
  weekDays,
  activeCode,
  availableCodes,
  currentDay,
  quickfill,
  setQuickfill,
  defaultCode,
  schoolDays,
  selectedTeacher,
  isClassView,
  selectedKlasses,
  showUnenrolled,
  shouldShowTableForSubstitute,
}) {
  const [currentStudentId, setCurrentStudentId] = React.useState(null);
  const [comment, setComment] = useState("");
  const [commentHasChanged, setCommentHasChanged] = useState(false);
  const handleCommentChange = (event) => {
    setComment(event.target.value);
    setCommentHasChanged(true);
  };

  const params = useParams();

  useEffect(() => {
    const dayAttendance = student.attendances?.find(
      (a) => a.date === currentDay.format("YYYY-MM-DD")
    );
    setComment(dayAttendance?.comment ? dayAttendance.comment : "");
  }, [currentDay]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const showStudentUserCard = Boolean(anchorEl);

  const avatarClick = async (event, studentId) => {
    setCurrentStudentId(studentId);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentDayIsSchoolDay = (day) =>
    schoolDays.some(
      (_day) =>
        _day.date === day.format(dateTimeFormats.YYYYMMDD) &&
        (_day.school_day_designation?.category === "school_day" ||
          _day.day_template_id !== null)
    );

  function handleProfilePictureSrc() {
    if (student?.image_url) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <img
          alt="profile_image"
          src={student.image_url.url}
          style={{
            borderRadius: "100px",
            width: "40px",
            height: "40px",
            objectFit: "cover",
            marginRight: "1rem",
            cursor: "pointer",
          }}
          onClick={(e) => {
            avatarClick(e, student.id);
          }}
        />
      );
    }
    return (
      <Avatar
        sx={tableAvatar}
        style={{ marginRight: "1rem" }}
        onClick={(e) => {
          avatarClick(e, student.id);
        }}
      >
        {student.first_name?.charAt(0).toUpperCase()}
      </Avatar>
    );
  }

  return (
    <>
      <TableRow sx={attendanceRow(student.is_enrolled)}>
        <TableCell sx={attendanceTableName}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {handleProfilePictureSrc()}
            <Link
              to={`/school/${params.school_id}/students/${student.slug}/account`}
              underline="none"
              component={RouterLink}
            >
              <Typography>
                {`${student.last_name}, ${student.first_name}`}
              </Typography>
            </Link>
          </div>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          {student.medical_badge && <PrivacyTipOutlinedIcon />}
        </TableCell>
        {isClassView && (
          <TableCell sx={px0}>
            <Typography>{student.klass_abbreviation}</Typography>
          </TableCell>
        )}
        {isDayView ? (
          <>
            <ButtonGroup
              availableCodes={availableCodes}
              activeCode={activeCode}
              student={student}
              currentDay={currentDay}
              quickfill={quickfill}
              setQuickfill={setQuickfill}
              defaultCode={defaultCode}
              isDayView={isDayView}
              comment={comment}
              selectedTeacher={selectedTeacher}
              isClassView={isClassView}
              selectedKlasses={selectedKlasses}
              showUnenrolled={showUnenrolled}
            />
            <TableCell sx={{ width: "100%" }}>
              <TextField
                fullWidth
                placeholder="Comment"
                value={comment}
                onChange={handleCommentChange}
              />
              {commentHasChanged && (
                <AutoSave
                  watcher={{
                    student_id: student.id,
                    comment,
                    date: currentDay.format("YYYY-MM-DD"),
                  }}
                  saveMethod={attendanceService.recordAttendance}
                  successMessage="Saved"
                  progressMessage="Saving..."
                  afterSaving={() => setCommentHasChanged(false)}
                />
              )}
            </TableCell>
          </>
        ) : (
          weekDays.map((d) =>
            currentDayIsSchoolDay(d) && shouldShowTableForSubstitute(d) ? (
              <ButtonGroup
                key={d.format("DD")}
                availableCodes={availableCodes}
                activeCode={activeCode}
                student={student}
                quickfill={quickfill}
                setQuickfill={setQuickfill}
                defaultCode={defaultCode}
                currentDay={d}
                comment={comment}
                selectedTeacher={selectedTeacher}
                isClassView={isClassView}
                showUnenrolled={showUnenrolled}
              />
            ) : (
              <TableCell key={d.format("DD")} sx={attendanceTableAction} />
            )
          )
        )}
      </TableRow>
      <StudentUserCard
        studentId={currentStudentId}
        schoolId={params.school_id}
        anchorEl={anchorEl}
        handleClose={handleClose}
        showStudentUserCard={showStudentUserCard}
      />
    </>
  );
}
